import { Component, OnInit } from '@angular/core';
import { ContactService } from '../contact/services/contact.service';
import { Contact } from '../contact/models/contact.model';
import { ToastyConfig, ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],
  providers: [ContactService, ToastyConfig, ToastyService]
})
export class PartnerComponent implements OnInit {

  newsData: Array<any>;
  Contact: Contact = new Contact();
  loading = false;
  error: {};
  constructor(private contactService: ContactService, private toastyConfig: ToastyConfig, private toastyService: ToastyService) {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=pan+aromas',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/flipkart.png'
      }
    ];
    this.toastyConfig.position = 'top-center';
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.loadScript();
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/css/multiCarousel.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  sendMail() {
    this.loading = false;
    return this.contactService.contactForm(this.Contact).subscribe(
      data => this.Contact = data,
      error => this.error = error
    );
  }

}
