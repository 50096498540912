import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Contact } from './models/contact.model';
import { ContactService } from './services/contact.service';
import { ToastyConfig, ToastyService } from 'ng2-toasty';

import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css'],
    providers: [ContactService, ToastyConfig, ToastyService]
})
export class ContactComponent implements OnInit, AfterViewInit {

    @ViewChild('mapContainer', { static: false }) gmap: ElementRef;

    map: google.maps.Map;

    lat = 19.078580;
    lng = 72.910100;

    coordinates = new google.maps.LatLng(this.lat, this.lng);

    mapOptions: google.maps.MapOptions = {
        center: this.coordinates,
        zoom: 13
    };

    marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
    });

    ngAfterViewInit() {
        this.mapInitializer();
    }

    mapInitializer() {
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
        this.marker.setMap(this.map);
    }

    newsData: Array<any>;
    Contact: Contact = new Contact();
    loading = false;
    error: {};
    constructor(private contactService: ContactService, private toastyConfig: ToastyConfig, private toastyService: ToastyService) {
        this.newsData = [
            {
                title: 'https://www.amazon.in/s?k=pan+aromas',
                src: '../assets/amazon.png'
            },
            {
                title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
                src: '../assets/nykaa.png'
            },
            {
                title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
                src: '../assets/flipkart.png'
            },
            {
                title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
                src: '../assets/igp.png'
            },
            {
                title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
                src: '../assets/seniority.png'
            }
        ];
        this.toastyConfig.position = 'top-center';
        this.toastyConfig.theme = 'bootstrap';
    }

    ngOnInit() {
        this.loadScript();
    }

    public loadScript() {
        const node = document.createElement('script');
        node.src = 'assets/css/multiCarousel.js';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    sendMail() {
        this.loading = false;
        return this.contactService.contactForm(this.Contact).subscribe(
            data => this.Contact = data,
            error => this.error = error
        );
    }   

}
