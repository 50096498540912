import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-candles',
  templateUrl: './candles.component.html',
  styleUrls: ['./candles.component.css']
})
export class CandlesComponent implements OnInit {

  newsData: Array<any>;

  fragrances: Array<any>;
  selectedIndex = [];
  fragranceImage = [];
  images: [];

  constructor(private dialog: MatDialog) {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=pan+aromas',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/flipkart.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/igp.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/seniority.png'
      }
    ];
  }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['Description', 'Product', 'Fragrance'];

  ngOnInit() {
    this.loadProducts();
    this.loadScript();

  }

  loadProducts() {
    var productData = [
      {
        productId: 1,
        productName: "SHRINK SLEEVES GLASS CANDLE",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 20-22 Hrs",
        specification2: "Wax Per Pc: 85 gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/1/1.jpg", "../assets/products/1/2.jpg", "../assets/products/1/3.jpg", "../assets/products/1/4.jpg", "../assets/products/1/5.jpg", "../assets/products/1/6.jpg"],
        itemCode: "PA-85SS",
        price: "Rs. 150/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "Glass Candle",
        useCase: "Try It"
      },
      {
        productId: 2,
        productName: "JAR GLASS CANDLE WITH LID",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 20-22 Hrs",
        specification2: "Wax Per Pc: 85 gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/2/1.jpg", "../assets/products/2/2.jpg", "../assets/products/2/3.jpg", "../assets/products/2/4.jpg", "../assets/products/2/5.jpg", "../assets/products/2/6.jpg"],
        itemCode: "PA-85JG",
        price: "Rs. 150/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "Candles",
        useCase: "Use It"
      },
      {
        productId: 3,
        productName: 'SCENTED PILLAR CANDLE (2.8" x 3.5")',
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 30-32 Hrs",
        specification2: "Wax Per Pc: 240 gms",
        //specification3: "Fragrance: 3%",
        productImage: ["../assets/products/3/1.jpg", "../assets/products/3/2.jpg", "../assets/products/3/3.jpg", "../assets/products/3/4.jpg", "../assets/products/3/5.jpg", "../assets/products/3/6.jpg"],
        itemCode: "PA-PLR2835",
        price: "Rs. 150/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Gardenia", color: "color74C36A" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Small Pillar candle for home decor, gifting, etc",
        useCase: "Simple light the candle at your home, office or place of your choice and sense the aroma."
      },
      {
        productId: 4,
        productName: 'SCENTED PILLAR CANDLE (2.8" x 6")',
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 60 - 62 Hrs",
        specification2: "Wax Per Pc: 410 gms",
        //specification3: "Fragrance: 3%",
        productImage: ["../assets/products/4/1.jpg", "../assets/products/4/2.jpg", "../assets/products/4/3.jpg", "../assets/products/4/4.jpg", "../assets/products/4/5.jpg", "../assets/products/4/6.jpg"],
        itemCode: "PA-PLR286",
        price: "Rs. 250/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Gardenia", color: "color74C36A" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Big pillar candle can be used to create peaceful aromas in your house.",
        useCase: "Just light the candle and sense the essence of aromas"
      },
      {
        productId: 5,
        productName: "15-PACK SCENTED TEALIGHT CANDLE",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 2.5 - 3 Hrs (Each)",
        specification2: "Wax Per Pc: 10 gms",
        //specification3: "Fragrance: 3%",
        productImage: ["../assets/products/5/1.jpg", "../assets/products/5/2.jpg", "../assets/products/5/3.jpg", "../assets/products/5/4.jpg", "../assets/products/5/5.jpg", "../assets/products/5/6.jpg", "../assets/products/5/7.jpg", "../assets/products/5/8.jpg", "../assets/products/5/9.jpg", "../assets/products/5/10.jpg"],
        itemCode: "PA-15TL",
        price: "Rs. 125/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
          { name: "Jasmine", color: "color74C36A" },
          { name: "Sandalwood", color: "color8B6C29" },
          { name: "Strawberry flower & Lily", color: "colorCB886B" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Small tealight candle can be used in many places",
        useCase: "Can be used in festivals as well as for decor and gifting"
      },
      {
        productId: 6,
        productName: "3-PACK SHOT GLASS CANDLE",
        //package: "Pkg: 6 pc in master carton",
        specification1: "Burning Time: 10-12 Hrs (individual)",
        specification2: "Wax Per Pc: 40 gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/6/1.jpg", "../assets/products/6/2.jpg"],
        itemCode: "PA-3PSG",
        price: "Rs. 200/-",
        fragrancesWithColor: [
          { name: "Fresh Linen, French Vanilla, Apple & Cinnamon", color: "colorGrad1" },
          { name: "Fresh Lavender, Jasmine, Rose", color: "colorGrad2" },
          /*{ name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Jasmine", color: "color74C36A" },
          { name: "Rose", color: "colorFE0032" },*/
        ],
        fullDescription: "3 in 1 pack for decoration of interior in home and office",
        useCase: "Can be used as gifting and interior decoration"
      },
      {
        productId: 7,
        productName: "6-PACK VOTIVE GLASS CANDLE",
        //package: "Pkg: 6 pc in master carton",
        specification1: "Burning Time: 7- 8 Hrs (individual)",
        specification2: "Wax Per Pc: 28 gms",
        //specification3: "Fragrance: 4%",
        productImage: ["../assets/products/7/1.jpg", "../assets/products/7/2.jpg", "../assets/products/7/3.jpg", "../assets/products/7/4.jpg", "../assets/products/7/5.jpg", "../assets/products/7/6.jpg"],
        itemCode: "PA-6PVG",
        price: "Rs. 300/-",
        fragrancesWithColor: [
          { name: "Cotton Fresh", color: "colorE1EEE7" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Jasmine", color: "color74C36A" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "6 pack votive style glass candle for changing the mood of any interior",
        useCase: "Use for gifting and interior decoration"
      },
      {
        productId: 8,
        productName: "45-PACK SCENTED TEALIGHT CANDLE",
        //package: "Pkg: 4 pc in master carton",
        specification1: "Burning Time: 2.5 to 3 Hrs (Each)",
        specification2: "Wax Per Pc: 10 gms",
        //specification3: "Fragrance: 3%",
        productImage: ["../assets/products/8/1.jpg", "../assets/products/8/2.jpg", "../assets/products/8/3.jpg", "../assets/products/8/4.jpg"],
        itemCode: "PA-45STL",
        price: "Rs. 360/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "45 pack scented candles",
        useCase: "Use each tealight to decorate any interior or exterior. Also can be used for gifting"
      },
      {
        productId: 9,
        productName: "50-PACK UNSCENTED TEALIGHT CANDLE",
        //package: "Pkg: 20 pc in master carton",
        specification1: "Burning Time: 2.5 Hrs (each)",
        specification2: "Wax Per Pc: 10 gms",
        //specification3: "Fragrance: Nil",
        productImage: ["../assets/products/9/1.jpg"],
        itemCode: "PA-50WTL",
        price: "Rs. 225/-",
        fragrancesWithColor: [
          { name: "Unscented White", color: "colorFFFFFF" },
        ],
        fullDescription: "unscented candles of 50 pack",
        useCase: "Used for formal occasions or for gifting"
      },
      {
        productId: 10,
        productName: "CRYSTAL COLLECTION 120 gms - COLOURED GLASS CANDLE",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 30 - 32 Hrs",
        specification2: "Wax Per Pc: 120 gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/10/1.jpg", "../assets/products/10/2.jpg"],
        itemCode: "PA-120CCGC",
        price: "Rs. 250/-",
        fragrancesWithColor: [
          { name: "Jasmine", color: "color74C36A" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Crystal collection is premium colored glass candle for decoration",
        useCase: "For interior decoration and gifting"
      },
      {
        productId: 11,
        productName: "6-PACK SCENTED TEALIGHT CANDLE",
        //package: "Pkg: 24 pc in master carton",
        specification1: "Burning Time: 4 hrs",
        specification2: "Wax Per Pc: 12gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/11/1.jpg", "../assets/products/11/2.jpg"],
        itemCode: "PA-6TLPC",
        price: "Rs. 150/-",
        fragrancesWithColor: [
          { name: "Orchard Blossom", color: "colorF6C6DC" },
          { name: "Rose", color: "colorFE0032" },
        ],
        fullDescription: "Decoration and gifting tealight candles",
        useCase: "For gifting and decoration purpose"
      },
      {
        productId: 12,
        productName: "BUCKET CANDLE",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Burning Time: 28-30 Hrs",
        specification2: "Wax Per Pc: 120 gms",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/12/1.jpg"],
        itemCode: "PA-120BCNL",
        price: "Rs. 200/-",
        fragrancesWithColor: [
          { name: "Citronella", color: "colorFFFF00" },
        ],
        fullDescription: "For home fragrance, office fragrance for occasion",
        useCase: "Burn on the other end"
      }
    ]

    this.listData = new MatTableDataSource(productData);
    this.fragrances = productData;
    productData.forEach(product => {
      this.fragranceImage.push(product.productImage[0]);
    });
  }

  selectFragrance(product: string | number, fragrance: string | number) {
    this.fragranceImage[product] = this.fragrances[product].productImage[fragrance];
    this.selectedIndex[product] = fragrance;
    console.log(this.selectedIndex);
  }

  openDialog(row: any) {
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '80vw',
      maxHeight: '80vh',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/css/multiCarousel.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
