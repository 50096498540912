export class Contact {
    name: string;
    email: string;
    phone: string;
    pinCode: string;
    message: string;
}

export class Subscriber {
    email_address: string;
    status = 'subscribed';
}

export interface MailChimpResponse {
    result: string;
    msg: string;
}
