export class AppConfig {
    // public readonly webUrl = 'http://localhost:4200';
    // public readonly apiUrl = 'https://localhost:44370/api/';
    // public readonly ImageUrl = 'https://localhost:44370/images/';


    //public readonly apiUrl = 'http://recreationlife-001-site15.dtempurl.com/api/';
    //public readonly apiUrl = "http://webservices.goldenoak.in/php/";
    //public readonly apiUrl = "http://localhost/web-services/goldenOak/";
    public readonly apiUrl = "http://inventifwebllp.com/webservices/";
    public readonly ImageUrl = 'http://recreationlife-001-site15.dtempurl.com/images/';
    public readonly webUrl = 'http://goldenoakkk.inventifwebllp.com';
    //public readonly webUrl = 'http://newsite.goldenoak.in';
}
