import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../_config/app.config';
import { Contact } from 'src/app/contact/models/contact.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ContactService {

  serverUrl = "http://inventifwebllp.com/webservices/";

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  [x: string]: any;

  data: any;

  constructor(private route: Router, private httpClient: HttpClient) {
  }

  contactForm(formdata: Contact) {
    return this.httpClient.post<Contact>(this.serverUrl + 'send-mail.php', formdata, this.httpOptions).pipe(
      catchError(this.handleError)
    )
  }
}