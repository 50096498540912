import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-giftsets',
  templateUrl: './giftsets.component.html',
  styleUrls: ['./giftsets.component.css']
})
export class GiftsetsComponent implements OnInit {

  newsData: Array<any>;

  fragrances: Array<any>;
  selectedIndex = [];
  fragranceImage = [];
  images: [];

  constructor(private dialog: MatDialog) {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=pan+aromas',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/flipkart.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/igp.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/seniority.png'
      }
    ];
  }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['Description', 'Product', 'Fragrance'];

  ngOnInit() {
    this.loadProducts();
    this.loadScript();

  }

  loadProducts() {
    var productData = [
      {
        productId: 1,
        productName: 'REED DIFFUSER - POT GIFT SET',
        //package: "Pkg: 12 pc in master carton",
        specification1: "Ceramic Pot (1N)",
        specification2: "Diffuser Fragrance Oil Per Pc: 50ml",
        specification3: "No. of Reeds Per Pc: 5",
        //specification4: "Fragrance: 5%",
        productImage: ["../assets/products/16/1.jpg", "../assets/products/16/2.jpg", "../assets/products/16/3.jpg", "../assets/products/16/4.jpg"],
        itemCode: "PA-RDP1",
        price: "Rs. 350/-",
        fragrancesWithColor: [
          { name: "Amber Rose", color: "colorEE2839" },
          { name: "Aqua Spa", color: "color4391CD" },
          { name: "Citrus Zest", color: "colorCCED4D" },
          { name: "Orange Blossom", color: "colorFD7E2D" },
        ],
        fullDescription: "Big pillar candle can be used to create peaceful aromas in your house.",
        useCase: "Just light the candle and sense the essence of aromas"
      },
      {
        productId: 2,
        productName: "BURNER FRAGRANCE OIL TEALIGHT - GIFT SET",
        //package: "Pkg: 4 pc in master carton",
        specification1: "Ceramic Burner (1 N)",
        specification2: "10ml - Fragrance Oil Bottle (1 N)",
        specification3: "10gm - Scented Tealight Candle (2 N)",
        productImage: ["../assets/products/20/1.jpg", "../assets/products/20/2.jpg", "../assets/products/20/3.jpg", "../assets/products/20/4.jpg", "../assets/products/20/5.jpg", "../assets/products/20/6.jpg", ],
        itemCode: "PA-BGS1",
        price: "Rs. 300/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Glass Candle",
        useCase: "Try It"
      },
      {
        productId: 3,
        productName: "BURNER ASSORTED FRAGRANCE OIL GIFT SET",
        //package: "Pkg: 9 pc in master carton",
        specification1: "1 Ceramic Burner",
        specification2: "4 x 10ml Fragrance Oil",
        //specification3: "",
        productImage: ["../assets/products/21/1.png"],
        itemCode: "PA-BGS2",
        price: "Rs. 400/-",
        fragrancesWithColor: [
          { name: "Assorted", color: "colorCCCCCC" },
        ],
        fullDescription: "Candles",
        useCase: "Use It"
      },
      {
        productId: 4,
        productName: 'WAX MELT - FRAGRANCE OIL - WARMER TEALIGHT - GIFT SET',
        //package: "Pkg: 6 pc in master carton",
        specification1: "Ceramic Burner (1N)",
        specification2: "45gm-Wax Melt (1N)",
        specification3: "10ml - Fragrance Oil Bottle (1N)",
        specification4: "12gm - Scented Tealight Candle (2N)",
        productImage: ["../assets/products/22/1.jpg", "../assets/products/22/2.jpg"],
        itemCode: "PA-BGS3",
        price: "Rs. 500/-",
        fragrancesWithColor: [
          { name: "Chocolate", color: "color563C25" },
          { name: "Coffee", color: "color88542C" },
        ],
        fullDescription: "Small Pillar candle for home decor, gifting, etc",
        useCase: "Simple light the candle at your home, office or place of your choice and sense the aroma."
      },
      {
        productId: 5,
        productName: '3-PACK REED DIFFUSER',
        //package: "Pkg: 6 pc in master carton",
        specification1: "Diffuser Fragrance Oil Per Pk: 3 x 50ml",
        specification2: "No. of Reeds Per Pk: 15",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/23/1.jpg"],
        itemCode: "PA-3PRD",
        price: "Rs. 500/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon, French Vanilla, Fresh Linen", color: "colorGrad3" },
          /*{ name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },*/
        ],
        fullDescription: "Big pillar candle can be used to create peaceful aromas in your house.",
        useCase: "Just light the candle and sense the essence of aromas"
      },
      {
        productId: 6,
        productName: "PAN AROMAS - BOUQUET",
        //package: "",
        specification1: "85gms Jar Candle (1N)",
        specification2: "15 pk Tealight (1N)",
        specification3: "Shrink Sleeve (1N)",
        specification4: "50ml Reed Diffuser (1N)",
        specification5: "20ml Fragrance Oil (1N)",
        specification6: "Potpourri 150gm (1N)",
        specification7: "60 pk Incense Stick (1N)",
        specification8: "Gift Bag (1N)",
        productImage: ["../assets/products/24/1.jpg", "../assets/products/24/2.jpg", "../assets/products/24/3.jpg", "../assets/products/24/4.jpg", "../assets/products/24/5.jpg", "../assets/products/24/6.jpg"],
        itemCode: "PA-7BQ",
        price: "Rs. 1250/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "Small tealight candle can be used in many places",
        useCase: "Can be used in festivals as well as for decor and gifting"
      }
    ]

    this.listData = new MatTableDataSource(productData);
    this.fragrances = productData;
    productData.forEach(product => {
      this.fragranceImage.push(product.productImage[0]);
    });
  }

  selectFragrance(product: string | number, fragrance: string | number) {
    this.fragranceImage[product] = this.fragrances[product].productImage[fragrance];
    this.selectedIndex[product] = fragrance;
    console.log(this.selectedIndex);
  }

  openDialog(row: any) {
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '80vw',
      maxHeight: '80vh',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/css/multiCarousel.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
