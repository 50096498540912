import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})

export class DialogComponent implements OnInit {
    fragranceImage: any;
    selectedIndex: any;
    images: [];
    constructor(public dialogbox: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.images = data.productImage;
        this.fragranceImage = data.productImage[0];
    }

    ngOnInit() {
        
    }

    selectImage(index: string | number) {
        this.selectedIndex = index;
        this.fragranceImage = this.images[index];
    }

    onNoClick(): void {
        this.dialogbox.close();
    }
}