import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-fragranceproducts',
  templateUrl: './fragranceproducts.component.html',
  styleUrls: ['./fragranceproducts.component.css']
})
export class FragranceproductsComponent implements OnInit {

  newsData: Array<any>;

  fragrances: Array<any>;
  selectedIndex = [];
  fragranceImage = [];
  images: [];

  constructor(private dialog: MatDialog) {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=pan+aromas',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/flipkart.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/igp.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/seniority.png'
      }
    ];
  }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['Description', 'Product', 'Fragrance'];

  ngOnInit() {
    this.loadProducts();
    this.loadScript();

  }

  loadProducts() {
    var productData = [
      {
        productId: 1,
        productName: "60-PACK SCENTED INCENSE STICK",
        //package: "Pkg: 12 pc in master carton",
        specification1: "60 Incense Stick",
        specification2: "1 Wooden Holder",
        specification3: "Net Wt: Approx 80 gms",
        //specification4: "Fragrance: 3%",
        productImage: ["../assets/products/13/1.jpg", "../assets/products/13/2.jpg", "../assets/products/13/3.jpg", "../assets/products/13/4.jpg", "../assets/products/13/5.jpg", "../assets/products/13/6.jpg"],
        itemCode: "PA-60IS",
        price: "Rs. 100/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "Glass Candle",
        useCase: "Try It"
      },
      {
        productId: 2,
        productName: "40-PACK CITRONELLA INCENSE STICK",
        //package: "Pkg: 12 pc in master carton",
        specification1: "40 Incense Stick",
        specification2: "1 Wooden Holder",
        //specification3: "Fragrance: 3%",
        productImage: ["../assets/products/14/1.jpg"],
        itemCode: "PA-401SCNL",
        price: "Rs. 160/-",
        fragrancesWithColor: [
          { name: "Citronella", color: "colorFFFF00" },
        ],
        fullDescription: "Candles",
        useCase: "Use It"
      },
      {
        productId: 3,
        productName: '50ml - REED DIFFUSER',
        //package: "Pkg: 12 pc in master carton",
        specification1: "Diffuser Fragrance Oil Per Pc: 50ml",
        specification2: "No. of Reeds Per Pc: 5",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/15/1.jpg", "../assets/products/15/2.jpg", "../assets/products/15/3.jpg", "../assets/products/15/4.jpg", "../assets/products/15/5.jpg", "../assets/products/15/6.jpg"],
        itemCode: "PA-50RD",
        price: "Rs. 200/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "Small Pillar candle for home decor, gifting, etc",
        useCase: "Simple light the candle at your home, office or place of your choice and sense the aroma."
      },
      {
        productId: 4,
        productName: "20ml - FRAGRANCE OIL",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Fragrance Oil Per Pc: 20ml",
        specification2: "(2 x 10ml Glass Bottle)",
        //specification3: "Fragrance: 5%",
        productImage: ["../assets/products/17/1.jpg", "../assets/products/17/2.jpg", "../assets/products/17/3.jpg", "../assets/products/17/4.jpg", "../assets/products/17/5.jpg", "../assets/products/17/6.jpg", "../assets/products/17/7.jpg", "../assets/products/17/8.jpg", "../assets/products/17/9.jpg"],
        itemCode: "PA-20FO",
        price: "Rs. 125/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
          { name: "Jasmine", color: "color74C36A" },
          { name: "Sandalwood", color: "color8B6C29" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
        ],
        fullDescription: "Small tealight candle can be used in many places",
        useCase: "Can be used in festivals as well as for decor and gifting"
      },
      {
        productId: 5,
        productName: "500ml - VAPORIZER OIL",
        //package: "Pkg: 4 pc in master carton",
        specification1: "Fragrance Oil Per Pc: 500ml",
        //specification2: "Fragrance: 5%",
        //specification3: "",
        productImage: ["../assets/products/18/1.jpg", "../assets/products/18/2.jpg", "../assets/products/18/3.jpg", "../assets/products/18/4.jpg"],
        itemCode: "PA-500VO",
        price: "Rs. 550/-",
        fragrancesWithColor: [
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
          { name: "Orchard Blossom", color: "colorF6C6DC" },
          { name: "Oudh", color: "color0A7A3F" },
        ],
        fullDescription: "3 in 1 pack for decoration of interior in home and office",
        useCase: "Can be used as gifting and interior decoration"
      },
      {
        productId: 6,
        productName: "150gms - POTPOURRI",
        //package: "Pkg: 12 pc in master carton",
        specification1: "Potpourri Flowers: 150gms",
        //specification2: "Fragrance: 5%",
        //specification3: "",
        productImage: ["../assets/products/19/1.jpg", "../assets/products/19/2.jpg", "../assets/products/19/3.jpg", "../assets/products/19/4.jpg", "../assets/products/19/5.jpg", "../assets/products/19/6.jpg"],
        itemCode: "PA-150PP",
        price: "Rs. 150/-",
        fragrancesWithColor: [
          { name: "Apple & Cinnamon", color: "colorEC2923" },
          { name: "French Vanilla", color: "colorF8F189" },
          { name: "Fresh Linen", color: "color6EA8DA" },
          { name: "Rose", color: "colorFE0032" },
          { name: "Fresh Lavender", color: "color965EA7" },
          { name: "Lemon Grass", color: "colorDBE13F" },
        ],
        fullDescription: "6 pack votive style glass candle for changing the mood of any interior",
        useCase: "Use for gifting and interior decoration"
      }
    ]

    this.listData = new MatTableDataSource(productData);
    this.fragrances = productData;
    productData.forEach(product => {
      this.fragranceImage.push(product.productImage[0]);
    });
  }

  selectFragrance(product: string | number, fragrance: string | number) {
    this.fragranceImage[product] = this.fragrances[product].productImage[fragrance];
    this.selectedIndex[product] = fragrance;
    console.log(this.selectedIndex);
  }

  openDialog(row: any) {
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '80vw',
      maxHeight: '80vh',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/css/multiCarousel.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
