import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CareerComponent } from './career/career.component';
import { PartnerComponent } from './partner/partner.component';
import { AffiliateComponent } from './affiliate/affiliate.component';

import { CandlesComponent } from './products/candles/candles.component';
import { FragranceproductsComponent } from './products/fragranceproducts/fragranceproducts.component';
import { FragrancesComponent } from './products/fragrances/fragrances.component';
import { GiftsetsComponent } from './products/giftsets/giftsets.component';
import { PremiumgiftingComponent } from './products/premiumgifting/premiumgifting.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'products', component: ProductsComponent, children: [
    { path: 'candles', component: CandlesComponent },
    { path: 'fragranceproducts', component: FragranceproductsComponent },
    { path: 'fragrances', component: FragrancesComponent },
    { path: 'giftsets', component: GiftsetsComponent },
    { path: 'premiumgifting', component: PremiumgiftingComponent }
  ]},
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'career', component: CareerComponent },
  { path: 'partner', component: PartnerComponent },
  { path: 'affiliate', component: AffiliateComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
