import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  newsData: Array<any>;
  constructor() {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=pan+aromas',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/flipkart.png'
      },
      {
        title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
        src: '../assets/igp.png'
      },
      {
        title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
        src: '../assets/seniority.png'
      }
    ];
  }

  ngOnInit() {
    this.loadScript();
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/css/multiCarousel.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
