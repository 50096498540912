import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit {

    newsData: Array<any>;

    fragrances: Array<any>;
    selectedIndex = [];
    fragranceImage = [];
    images: [];

    constructor(private dialog: MatDialog) {
        this.newsData = [
            {
                title: 'https://www.amazon.in/s?k=pan+aromas',
                src: '../assets/amazon.png'
            },
            {
                title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
                src: '../assets/nykaa.png'
            },
            {
                title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
                src: '../assets/flipkart.png'
            },
            {
                title: 'https://www.nykaa.com/search/result/?q=Pan+Aromas',
                src: '../assets/igp.png'
            },
            {
                title: 'https://www.flipkart.com/search?q=Pan%20Aromas',
                src: '../assets/seniority.png'
            }
        ];
    }

    listData : MatTableDataSource<any>;
    displayedColumns: string[] = ['Description', 'Product', 'Fragrance'];

    ngOnInit() {
        this.loadProducts();
        this.loadScript();
        
    }

    loadProducts() {
        var productData = [
            { 
                productId: 1,
                productName: "Shrink Sleeve Glass Candle", 
                package: 12, 
                specification1: "Burning Time: 20-22 Hrs", 
                specification2: "Wax Per Pc: 85 gms", 
                specification3: "Fragrance: 5%", 
                productImage: ["../assets/products/1/1.png", "../assets/products/1/2.png", "../assets/products/1/3.png", "../assets/products/1/4.png", "../assets/products/1/5.png", "../assets/products/1/6.png", "../assets/products/1/7.png", "../assets/products/1/8.png"], 
                itemCode: "PA-85SS",
                fragrances: ["Apple & Cinnamon", "Chocolate", "Rose", "Lemon Grass", "Coffee", "Fresh Lavender", "Fresh Linen", "French Vanilla"],
                fullDescription: "Glass Candle",
                useCase: "Try It"
            }, 
            { 
                productId: 2,
                productName: "Jar Glass Candle with Lid", 
                package: 12, 
                specification1: "Burning Time: 20-22 Hrs", 
                specification2: "Wax Per Pc: 85 gms", 
                specification3: "Fragrance: 5%", 
                productImage: ["../assets/products/2/1.png", "../assets/products/2/2.png", "../assets/products/2/3.png", "../assets/products/2/4.png", "../assets/products/2/5.png", "../assets/products/2/6.png"], 
                itemCode: "PA-85JG",
                fragrances: ["Apple & Cinnamon", "French Vanilla", "Fresh Linen", "Rose", "Fresh Lavender", "Lemon Grass"],
                fullDescription: "Candles",
                useCase: "Use It"
            },
            {
                productId: 3,
                productName: "Scented Pillar Candle (2.8 x 3.5 inches)",
                package: 12,
                specification1: "Burning Time: 60-62 Hrs",
                specification2: "Wax per pc: 240 gms",
                specification3: "Fragrance: 3%",
                productImage: ["../assets/products/3/1.png", "../assets/products/3/2.png", "../assets/products/3/3.png", "../assets/products/3/4.png", "../assets/products/3/5.png", "../assets/products/3/6.png"],
                itemCode: "PPAPLR2835",
                fragrances: ["French Vanilla", "Apple & Cinnamon", "Fresh Lavender", "Jasmine", "Orchard Blossom", "Unscented"],
                fullDescription: "Small Pillar candle for home decor, gifting, etc",
                useCase: "Simple light the candle at your home, office or place of your choice and sense the aroma."
            },
            {
                productId: 4,
                productName: "Scented Pillar Candle (2.8 x 6 inches)",
                package: 12,
                specification1: "Burning Time: 30 - 32 Hrs",
                specification2: "Wax per pc: 410 gms",
                specification3: "Fragrance: 3%",
                productImage: ["../assets/products/4/1.png", "../assets/products/4/2.png", "../assets/products/4/3.png", "../assets/products/4/4.png", "../assets/products/4/5.png", "../assets/products/4/6.png", "../assets/products/4/7.png"],
                itemCode: "PAPLR286",
                fragrances: ["Apple Cinnamon", "Fresh Lavender", "French Vanilla", "Fresh Lenin", "Jasmine", "Orchard Blossom", "Unscented"],
                fullDescription: "Big pillar candle can be used to create peaceful aromas in your house.",
                useCase: "Just light the candle and sense the essence of aromas"
            },
            {
                productId: 5,
                productName: "15-Pack Scented Tealight Candle",
                package: 12,
                specification1: "Burning Time: 2.5 - 3 Hrs",
                specification2: "Wax per pc: 10 gms",
                specification3: "Fragrance: 3%",
                productImage: ["../assets/products/5/1.png", "../assets/products/5/2.png", "../assets/products/5/3.png", "../assets/products/5/4.png", "../assets/products/5/5.png", "../assets/products/5/6.png", "../assets/products/5/7.png", "../assets/products/5/8.png", "../assets/products/5/9.png"],
                itemCode: "PA15TL",
                fragrances: ["Apple & Cinnamon", "French Vanilla", "Fresh Lenin", "Rose", "Fresh Lavender", "Lemon Grass", "Jasmine", "Sandalwood", "Strawberry flower & Lily"],
                fullDescription: "Small tealight candle can be used in many places",
                useCase: "Can be used in festivals as well as for decor and gifting"
            },
            {
                productId: 6,
                productName: "3- Pack Shot Glass Candle",
                package: 6,
                specification1: "Burning Time: 10-12 Hrs (individual)",
                specification2: "Wax per pc: 40 gms",
                specification3: "Fragrance: 5%",
                productImage: ["../assets/products/6/1.png", "../assets/products/6/2.png"],
                itemCode: "PA3PSG",
                fragrances: ["Apple & Cinnamon | French Vanilla | Fresh Lenin", "Fresh Lavender | Jasmine | Rose"],
                fullDescription: "3 in 1 pack for decoration of interior in home and office",
                useCase: "Can be used as gifting and interior decoration"
            },
            {
                productId: 7,
                productName: "6-Pack Votive Glass Candle",
                package: 6,
                specification1: "Burning Time: 7- 8 Hrs (individual)",
                specification2: "wax per pc: 28 gms",
                specification3: "Fragrance: 4%",
                productImage: ["../assets/products/7/1.png", "../assets/products/7/2.png", "../assets/products/7/3.png"],
                itemCode: "PA6PVG",
                fragrances: ["Cotton Fresh", "Jasmine", "French Vanilla"],
                fullDescription: "6 pack votive style glass candle for changing the mood of any interior",
                useCase: "Use for gifting and interior decoration"
            },
            {
                productId: 8,
                productName: "45-Pack Scented Tealight Candle",
                package: 4,
                specification1: "Burning Time: 2.5 to 3 Hrs",
                specification2: "Wax per pc: 10 gms",
                specification3: "Fragrance: 3%",
                productImage: ["../assets/products/8/1.png", "../assets/products/8/2.png", "../assets/products/8/3.png", "../assets/products/8/4.png"],
                itemCode: "PA45STL",
                fragrances: ["45-Pack Scented Tealight Candle", "French Vanilla", "Fresh Lavender", "Lemon Grass"],
                fullDescription: "45 pack scented candles",
                useCase: "Use each tealight to decorate any interior or exterior. Also can be used for gifting"
            },
            {
                productId: 9,
                productName: "45-Pack Unscented Tealight Candle",
                package: 4,
                specification1: "Burning Time: 2.5 to 3 Hrs (each)",
                specification2: "Wax per pc: 10 gms",
                specification3: "Fragrance: None",
                productImage: ["../assets/products/9/1.png"],
                itemCode: "PA45USTL",
                fragrances: ["Unscented white"],
                fullDescription: "unscented candles of 45 pack",
                useCase: "Used for formal occasions or for gifting"
            },
            {
                productId: 10,
                productName: "Crystal Collection 120 gm - Coloured Glass Candle",
                package: 12,
                specification1: "Burning Time: 30 - 32 Hrs",
                specification2: "Wax per pc: 120 gms",
                specification3: "Fragrance: 5%",
                productImage: ["../assets/products/10/1.png", "../assets/products/10/2.png"],
                itemCode: "PA120CCGC",
                fragrances: ["Jasmine", "Orchard Blossom"],
                fullDescription: "Crystal collection is premium colored glass candle for decoration",
                useCase: "For interior decoration and gifting"
            },
            {
                productId: 11,
                productName: "6-Pack Scented Tealight Candle",
                package: 24,
                specification1: "Burning Time: 4 hrs",
                specification2: "wax per pc: 12gms",
                specification3: "Fragrance: 5%",
                productImage: ["../assets/products/11/1.png", "../assets/products/11/2.png"],
                itemCode: "PA6TLPC",
                fragrances: ["Orchard Blossom", "Rose"],
                fullDescription: "Decoration and gifting tealight candles",
                useCase: "For gifting and decoration purpose"
            },
            {
                productId: 12,
                productName: "60-Pack Scented Incense Stick",
                package: 12,
                specification1: "Includes: 60 Incense stick, 1 Wood holder",
                specification2: "Net Wt: 80 gms (approx)",
                specification3: "Fragrance: 3%",
                productImage: ["../assets/products/12/1.png"],
                itemCode: "PA60IS",
                fragrances: ["Apple & Cinnamon"],
                fullDescription: "For home fragrance, office fragrance for occasion",
                useCase: "Burn on the other end"
            }
        ]

        this.listData = new MatTableDataSource(productData);
        this.fragrances = productData;
        productData.forEach(product => {
            this.fragranceImage.push(product.productImage[0]);
        });
    }

    selectFragrance(product: string | number, fragrance: string | number) {
        this.fragranceImage[product] = this.fragrances[product].productImage[fragrance];
        this.selectedIndex[product] = fragrance;
        console.log(this.selectedIndex);
    }

    openDialog(row: any) {
        let dialogRef = this.dialog.open(DialogComponent, {
            width: '80vw',
            maxHeight: '80vh',
            data: row
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    public loadScript() {
        const node = document.createElement('script');
        node.src = 'assets/css/multiCarousel.js';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}