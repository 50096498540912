import { Component, OnInit } from '@angular/core';
import { Subscriber } from '../contact/models/contact.model';
import { ContactService } from '../contact/services/contact.service';
import { AppConfig } from '../_config/app.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ContactService]
})
export class HomeComponent implements OnInit {

  newsData: Array<any>;
  url = 'assets/css/multiCarousel.js';
  subscriber: Subscriber = new Subscriber();
  submitted = false;
  error = '';
  WebUrl = new AppConfig().webUrl;
  candleLink = '/products?category=' + '2DBEBD6F-B18B-492D-BDBD-1D61F5901BB9';
  constructor(private contact: ContactService) {
    this.newsData = [
      {
        title: 'https://www.amazon.in/s?k=PAN+AROMAS&rh=n%3A1380380031&ref=nb_sb_noss',
        src: '../assets/amazon.png'
      },
      {
        title: 'https://www.nykaa.com/brands/pan-aromas/c/8006?root=search,brand_menu,brand_list,Pan%20Aromas&sourcepage=search&rtqu=Pan%20Aromas',
        src: '../assets/nykaa.png'
      },
      {
        title: 'https://www.igp.com/p-glass-candles-apple-cinnamon-lemongrass-french-vanilla-fresh-lavender-110146',
        src: '../assets/igp.png'
      },
      {
        title: 'https://www.seniority.in/catalogsearch/result/?q=Pan+Aromas',
        src: '../assets/seniority.png'
      }
    ];
  }

  public loadScript() {
    const node = document.createElement('script');
    node.src = this.url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnInit() {
    this.loadScript();
  }

}
